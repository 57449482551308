@use '../core/tokens/m2/mat/table' as tokens-mat-table;
@use '../core/theming/theming';
@use '../core/typography/typography';
@use '../core/tokens/token-utils';

@mixin _output-tokens {
  @if (&) {
    @content;
  }
  @else {
    html {
      @content;
    }
  }
}

@mixin color($config-or-theme) {
  $config: theming.get-color-config($config-or-theme);

  @include _output-tokens {
    @include token-utils.create-token-values(tokens-mat-table.$prefix,
      tokens-mat-table.get-color-tokens($config));
  }
}

@mixin typography($config-or-theme) {
  $config: typography.private-typography-to-2018-config(
      theming.get-typography-config($config-or-theme));

  @include _output-tokens {
    @include token-utils.create-token-values(tokens-mat-table.$prefix,
      tokens-mat-table.get-typography-tokens($config));
  }
}

@mixin density($config-or-theme) {
  $density-scale: theming.get-density-config($config-or-theme);

  @include _output-tokens {
    @include token-utils.create-token-values(tokens-mat-table.$prefix,
      tokens-mat-table.get-density-tokens($density-scale));
  }
}

@mixin theme($theme-or-color-config) {
  $theme: theming.private-legacy-get-theme($theme-or-color-config);
  @include theming.private-check-duplicate-theme-styles($theme, 'mat-table') {
    $color: theming.get-color-config($theme);
    $density: theming.get-density-config($theme);
    $typography: theming.get-typography-config($theme);

    @if $color != null {
      @include color($color);
    }
    @if $density != null {
      @include density($density);
    }
    @if $typography != null {
      @include typography($typography);
    }
  }
}
