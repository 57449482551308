$breakpointsMobile: (
  xs: 320px,
  s: 450px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1024px,
  2xl: 1200px,
  3xl: 1440px
);

$breakpointsPC: (
  xs: 319px,
  s: 449px,
  sm: 575px,
  md: 767px,
  lg: 991px,
  xl: 1023px,
  2xl: 1199px,
  3xl: 1439px
);
/* stylelint-disable */

@mixin responsiveMobileFirst($breakpoint) {
  @if map-has-key($breakpointsMobile, $breakpoint) {
    @media (min-width: map-get($breakpointsMobile, $breakpoint)) {
      @content;
    }
  } @else {
    @warn "No breakpoint defined for #{$breakpoint}.";
  }
}

@mixin responsivePCFirst($breakpoint) {
  @if map-has-key($breakpointsPC, $breakpoint) {
    @media (max-width: map-get($breakpointsPC, $breakpoint)) {
      @content;
    }
  } @else {
    @warn "No breakpoint defined for #{$breakpoint}.";
  }
}

@mixin responsiveRange($min-width, $max-width) {
  @include responsiveMobileFirst($min-width) {
    @include responsivePCFirst($max-width) {
      @content;
    }
  }
}
/* stylelint-enable */

:root {
  @include responsiveMobileFirst(3xl);
}
