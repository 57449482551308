/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;

@import '@ng-select/ng-select/themes/default.theme.css';
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import 'typography/typography';
@import 'typography/typographyUbs';
@import 'typography/resp';
@import '@angular/material/theming';

@include mat.core();

$main-palette: (
  50: #b0ffc2,
  100: #73dc8f,
  200: #56c572,
  300: #24b847,
  400: #159e36,
  500: var(--primary-green),
  600: #004a00,
  700: #52e452,
  800: #9e9d24,
  900: #827717,
  A100: #f4ff81,
  A200: #eeff41,
  A400: #c6ff00,
  A700: #aeea00,
  contrast: (
    50: #fff,
    100: var(--primary-white),
    200: var(--primary-white),
    300: var(--primary-white),
    400: var(--primary-white),
    500: var(--primary-white),
    600: var(--primary-white),
    700: var(--primary-white),
    800: var(--primary-white),
    900: var(--primary-white),
    A100: var(--primary-white),
    A200: var(--primary-white),
    A400: var(--primary-white),
    A700: var(--primary-white)
  )
);

$accent-palette: (
  100: black,
  400: #000,
  500: black,
  700: black,
  contrast: (
    50: #fff,
    100: var(--primary-white),
    200: var(--primary-white),
    300: var(--primary-white),
    400: var(--primary-white),
    500: var(--primary-white),
    600: var(--primary-white),
    700: var(--primary-white),
    800: var(--primary-white),
    900: var(--primary-white),
    A100: var(--primary-white),
    A200: var(--primary-white),
    A400: var(--primary-white),
    A700: var(--primary-white)
  )
);

$my-primary: mat.define-palette($main-palette, 400);
$my-accent: mat.define-palette($accent-palette, 400);

$my-warn: mat.define-palette(mat.$red-palette);

$my-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-accent,
      warn: $my-warn
    ),
    typography: mat.define-typography-config(),
    density: -3,
    text: var(--primary-white)
  )
);

@include angular-material-theme($my-theme);
@include mat.chips-density(0);
@include mat.checkbox-density(-1);

label {
  margin-bottom: 0 !important;
}

%font-base {
  font-weight: 400;
  font-family: Roboto, sans-serif;
  color: #64727d;
}

.xs-text {
  @extend %font-base;

  font-size: 10px;
  line-height: 12px;
}

.sm-text {
  @extend %font-base;

  font-size: 12px;
  line-height: 16px;
}

.regular-text {
  @extend %font-base;

  font-size: 16px;
  line-height: 24px;
}

.hide {
  display: none;
}

a {
  cursor: pointer;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
}

html {
  height: 100%;
  margin-right: calc(-100vw + 100%);
}

body {
  margin: 0;
  height: 100%;
  font-family: var(--tertiary-font);
  overflow: hidden auto;
}

.container {
  padding-left: 0;
  padding-right: 0;
  margin-inline: auto;

  @media screen and (max-width: 575px) {
    padding-left: 18px;
    padding-right: 18px;
  }

  @media screen and (max-width: 320px) {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.app-wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.main-content {
  flex: 1 1 auto;
}

.cdk-global-scrollblock {
  overflow-y: inherit !important;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  border: solid 2px rgb(19 170 87 / 80%);
  background-color: rgb(255 255 255 / 90%) !important;
}

.cdk-drag-placeholder {
  opacity: 0;
}

.extra-packages mat-dialog-container {
  background-color: var(--ubs-main-page-secondary-light-grey);
}

.cdk-overlay-pane {
  &.extra-packages {
    overflow: auto;
    max-width: 100%;
  }
}

.ubs-user-order-payment-pop-up-vertical-scroll {
  overflow-y: auto;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.example-box:last-child {
  border: none;
}

.example-list .cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

// [END] View swither btns
.transparent {
  mat-dialog-container {
    background: transparent;
    box-shadow: none;
  }
}

.mat-option.mat-active {
  background-color: var(--ubs-primary-light-green);
}

.mat-mdc-dialog-surface {
  padding: 30px;
}

.popup-dialog-container {
  mat-dialog-container {
    padding: 0;
    box-shadow: none;
  }
}

.add-place-wrapper-class {
  overflow-y: auto;

  mat-dialog-container {
    padding: 0;
    box-shadow: none;
  }
}

.delete-dialog-container {
  mat-dialog-container {
    box-shadow: none;
  }
}

.admin-cabinet-dialog-container {
  mat-dialog-container {
    padding: 0;
    display: block;
    box-shadow: none;
    border-radius: 4px;
    box-sizing: border-box;
    overflow: auto;
    overflow-x: hidden;
    outline: 0;
    width: 100%;
    height: 100%;
    min-height: inherit;
    max-height: 100vh;
    background: var(--primary-white);
  }
}

.mat-accent .mat-slider-track-fill,
.mat-accent .mat-slider-thumb,
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: var(--primary-green);
}

.custom-radio-btn {
  .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
    background-color: var(--ubs-quaternary-dark-grey) !important;
  }

  .mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: var(--ubs-quaternary-dark-grey) !important;
  }

  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: var(--ubs-quaternary-dark-grey) !important;
  }
}

.custom-dialog-container {
  mat-dialog-container {
    padding: 0;
    display: block;
    box-shadow: none;
    border-radius: 4px;
    box-sizing: border-box;
    overflow: hidden;
    outline: 0;
    width: 100%;
    height: 100%;
    min-height: inherit;
    max-height: 100vh;
    background: var(--primary-white);

    .mat-mdc-dialog-surface {
      padding: 0;
    }
  }
}

// [START] Tag guidelines
.global-tag {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  padding: 0 16px;
  text-transform: capitalize;
  font-family: var(--primary-font);
  color: var(--tertiary-grey);
  background: var(--primary-white);
  border: 1px solid var(--tertiary-grey);
  border-radius: 25px;
  margin-right: 8px;

  &:hover {
    color: var(--primary-green);
    border: 1px solid var(--primary-green);
  }
}

.global-tag-clicked {
  background: var(--primary-green);
  color: var(--primary-white);
  font-family: var(--primary-font);
  font-size: 14px;
  line-height: 20px;
  border-radius: 25px;
  border: none;

  &:hover {
    color: var(--primary-white);
    border: none;
  }
}

.global-tag-close-icon {
  width: 11px;
  height: 11px;
  margin-left: 9px;
  opacity: 1;
  background-size: contain;
  background-image: url('assets/img/icon/white-cross.png');
}

::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

::-webkit-scrollbar-track {
  background: var(--ubs-secondary-white);
}

::-webkit-scrollbar-thumb {
  background: var(--ubs-secondary-grey);
  box-shadow: 2px 2px 4px rgb(73 74 73 / 20%);
  border-radius: 4px;
  cursor: pointer;
}

// [END] Tag guidelines

// [STATR] View switcher buttons

.btn-tiles,
.btn-bars {
  height: 24px;
  width: 24px;
  color: var(--secondary-grey);
  text-align: center;
  background-color: transparent;
  padding: 0;
  border: 0;
  font-size: 16px;

  &:hover:enabled {
    color: var(--secondary-dark-green);
  }
}

.btn-bars-active,
.btn-tiles-active {
  color: var(--primary-green);
  cursor: default;
}

.error-snackbar {
  background-color: var(--error-light-red);
  color: var(--quaternary-dark-grey);
  border-radius: 4px;
  box-shadow: 0 -2px var(--error-red);
}

.attention-snackbar {
  background-color: var(--attention-light-yellow);
  color: var(--quaternary-dark-grey);
  border-radius: 4px;
  box-shadow: 0 -2px var(--attention-yellow);
}

.success-snackbar {
  background-color: var(--success-light-green);
  color: var(--quaternary-dark-grey);
  border-radius: 4px;
  box-shadow: 0 -2px var(--primary-green);
}

.success-snackbar-ubs {
  padding: 12px 16px !important;
  background-color: var(--ubs-primary-light-green);
  color: var(--ubs-quaternary-dark-grey);
  border-radius: 4px;
  box-shadow: 0 -2px var(--ubs-button-light-green);
}

.mat-mdc-snack-bar-container {
  .mdc-snackbar__surface {
    background-color: inherit !important;
  }

  .mdc-snackbar__label {
    color: var(--quaternary-dark-grey) !important;
  }
}

.mat-mdc-button {
  &.mat-mdc-snack-bar-action {
    min-width: 24px;
  }

  .mdc-button__label {
    display: none;
  }

  .mat-mdc-focus-indicator {
    background-image: url('assets/img/snackBar-close-icon.svg');
    display: block;
    margin: auto;
    background-size: 11px;
    width: 11px;
    height: 11px;
  }
}

.disagree {
  background: #fff;
  color: var(--ubs-primary-green);
  padding: 8px 28px;
  margin-right: 25px;
}

.agree {
  background: var(--ubs-primary-green);
  color: #fff;
  padding: 8px 28px;
}

.disagree,
.agree {
  font-weight: bold;
  font-size: 14px;
  border: 1px solid var(--primary-green);
}

@media screen and (max-width: 575px) {
  .cdk-overlay-pane {
    &.extra-packages {
      max-width: 100% !important;
      height: 100%;
    }
  }
}

// [END] View switcher buttons

// [STATR] Quill editor

.ql-editor {
  height: 199px;
  max-width: 594px;

  @media (max-width: 1199px) {
    max-width: 100%;
  }
}

.ql-toolbar {
  background-color: #eff0f1 !important;
}

// [END] Quill editor

.tooltipClass::before {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-bottom: -5px;
}

.tooltipClass {
  display: inline-block;
  visibility: visible;
  position: absolute;
  background-color: #fff;
  text-align: center;
  width: fit-content;
  overflow-wrap: break-word;
  word-wrap: break-word;
  padding: 5px;
  border-radius: 6px;
  z-index: 1000;
  top: 100%;
  left: -5px;
}

.tooltipClass-textArea {
  top: 62%;
  left: 15px;
  max-width: 221px;

  @media (max-width: 1199px) {
    max-width: 189px;
  }

  @media (max-width: 767px) {
    top: 70%;
    left: 90px;
    max-width: 457px;
  }

  @media (max-width: 567px) {
    top: 78%;
    left: calc((100% - 305px) / 2);
    max-width: 295px;
  }
}

.customize-mat-panel {
  margin-top: 15px;
}

.multiline-matTooltip {
  white-space: pre-line;
}

.mat-mdc-menu-panel {
  overflow: visible !important;
}

.mat-mdc-select-min-line {
  font-size: 12px;
  padding-left: 2px;
}
