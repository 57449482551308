@use 'sass:map';
@use '../core/theming/theming';
@use '../core/tokens/m2/mdc/circular-progress' as tokens-mdc-circular-progress;
@use '@material/circular-progress/circular-progress-theme' as mdc-circular-progress-theme;

@mixin color($config-or-theme) {
  $config: theming.get-color-config($config-or-theme);
  $mdc-circular-progress-color-tokens: tokens-mdc-circular-progress.get-color-tokens($config);

  .mat-mdc-progress-spinner {
    @include mdc-circular-progress-theme.theme($mdc-circular-progress-color-tokens);

    &.mat-accent {
      $color: theming.get-color-from-palette(map.get($config, accent));
      @include mdc-circular-progress-theme.theme((active-indicator-color: $color));
    }

    &.mat-warn {
      $color: theming.get-color-from-palette(map.get($config, warn));
      @include mdc-circular-progress-theme.theme((active-indicator-color: $color));
    }
  }
}

@mixin typography($config-or-theme) {}

@mixin density($config-or-theme) {}

@mixin theme($theme-or-color-config) {
  $theme: theming.private-legacy-get-theme($theme-or-color-config);
  @include theming.private-check-duplicate-theme-styles($theme, 'mat-progress-spinner') {
    $color: theming.get-color-config($theme);
    $density: theming.get-density-config($theme);
    $typography: theming.get-typography-config($theme);

    @if $color != null {
      @include color($color);
    }
    @if $density != null {
      @include density($density);
    }
    @if $typography != null {
      @include typography($typography);
    }
  }
}
