@use '@material/dialog/dialog-theme' as mdc-dialog-theme;
@use '../core/tokens/m2/mdc/dialog' as tokens-mdc-dialog;
@use '../core/theming/theming';
@use '../core/typography/typography';

@mixin color($config-or-theme) {
  $config: theming.get-color-config($config-or-theme);

  .mat-mdc-dialog-container {
    @include mdc-dialog-theme.theme(tokens-mdc-dialog.get-color-tokens($config));
  }
}

@mixin typography($config-or-theme) {
  $config: typography.private-typography-to-2018-config(
      theming.get-typography-config($config-or-theme));

  .mat-mdc-dialog-container {
    @include mdc-dialog-theme.theme(tokens-mdc-dialog.get-typography-tokens($config));
  }
}

@mixin density($config-or-theme) {
  $density-scale: theming.get-density-config($config-or-theme);
}

@mixin theme($theme-or-color-config) {
  $theme: theming.private-legacy-get-theme($theme-or-color-config);
  @include theming.private-check-duplicate-theme-styles($theme, 'mat-dialog') {
    $color: theming.get-color-config($theme);
    $density: theming.get-density-config($theme);
    $typography: theming.get-typography-config($theme);

    @if $color != null {
      @include color($color);
    }
    @if $density != null {
      @include density($density);
    }
    @if $typography != null {
      @include typography($typography);
    }
  }
}
