@use '@material/snackbar/snackbar-theme' as mdc-snackbar-theme;
@use '../core/theming/theming';
@use '../core/typography/typography';
@use '../core/tokens/token-utils';
@use '../core/tokens/m2/mdc/snack-bar' as tokens-mdc-snack-bar;
@use '../core/tokens/m2/mat/snack-bar' as tokens-mat-snack-bar;


@mixin color($config-or-theme) {
  $config: theming.get-color-config($config-or-theme);

  .mat-mdc-snack-bar-container {
    @include mdc-snackbar-theme.theme(tokens-mdc-snack-bar.get-color-tokens($config));
    @include token-utils.create-token-values(
      tokens-mat-snack-bar.$prefix,
      tokens-mat-snack-bar.get-color-tokens($config)
    );
  }
}

@mixin typography($config-or-theme) {
  $config: typography.private-typography-to-2018-config(
      theming.get-typography-config($config-or-theme));

  .mat-mdc-snack-bar-container {
    @include mdc-snackbar-theme.theme(tokens-mdc-snack-bar.get-typography-tokens($config));
  }
}

@mixin density($config-or-theme) {}

@mixin theme($theme-or-color-config) {
  $theme: theming.private-legacy-get-theme($theme-or-color-config);
  @include theming.private-check-duplicate-theme-styles($theme, 'mat-snack-bar') {
    $color: theming.get-color-config($theme);
    $density: theming.get-density-config($theme);
    $typography: theming.get-typography-config($theme);

    @if $color != null {
      @include color($color);
    }
    @if $density != null {
      @include density($density);
    }
    @if $typography != null {
      @include typography($typography);
    }
  }
}

