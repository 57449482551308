:root {
  --ubs-primary-white: #fff;
  --ubs-primary-black: #000;
  --ubs-secondary-white: #f7f9fa;
  --ubs-primary-green: #13aa57;
  --ubs-secondary-dark-green: #056b33;
  --ubs-tertiary-dark-green: #5b8901;
  --ubs-quaternary-dark-green: #6e9e0d;
  --ubs-tertiary-light-green: #39b54a;
  --ubs-grey-light-green: #d5efb8;
  --ubs-slightly-light-green: #d4e7ae;
  --ubs-secondary-light-green: #87bc1e;
  --ubs-grey-light-blue: #e6dbff;
  --ubs-grey-white: #f7f9fa;
  --ubs-error-base: #e0343c;
  --ubs-error-light-button-red: #fce0de;
  --ubs-error-ligh-red: #f03127;
  --ubs-primary-red: #eb180d;
  --ubs-dark-red: #bf140b;
  --ubs-primary-light-grey: #cacfd3;
  --ubs-grey-blue: #9ca7b0;
  --ubs-ligth-grey: #e9ecef;
  --ubs-secondary-light-grey: #f5f6f6;
  --ubs-tertiary-light-grey: #bebebe;
  --ubs-quaternary-light-grey: #e3e6e8;
  --ubs-quintynary-light-grey: #9ca7b0;
  --ubs-main-page-primary-light-grey: #b9b9b9;
  --ubs-main-page-secondary-light-grey: #f6f6f6;
  --ubs-main-page-tertiary-light-grey: #b3b3b3;
  --ubs-main-page-text-grey: #575757;
  --ubs-primary-grey: #444e55;
  --ubs-secondary-grey: #64727d;
  --ubs-tertiary-grey: #666;
  --ubs-primary-dark-grey: #8c8c8c;
  --ubs-secondary-dark-grey: #313131;
  --ubs-tertiary-dark-grey: #2b292d;
  --ubs-button-shadow: rgb(100 114 125 / 30%);
  --ubs-button-light-grey: #eff0f1;
  --ubs-button-light-green: #afd85a;
  --ubs-button-dark-green: #9ec848;
  --ubs-primary-light-green: #c2ea6f;
  --ubs-primary-lime-green: #93e22c;
  --ubs-liberty-green: #eaffbd;
  --ubs-black: #000;
  --ubs-quaternary-dark-grey: #1d2830;
  --ubs-primary-blue: #513be4;
  --ubs-dark-green: #336b4c;
  --ubs-secondary-dark-green: #263900;
  --ubs-dark-purple: #5639bb;
  --ubs-accent-purple: #9368f0;
  --ubs-electric-violet: #5639bb;
  --ubs-primary-liberty-green: #e7f7ee;
  --ubs-lighter-grey: #ced4da;
}
