:root {
  --primary-green: #13aa57;
  --primary-white: #fff;
  --secondary-dark-green: #056b33;
  --tertiary-dark-green: #336b4c;
  --primary-light-green: #b3e6c9;
  --success-light-green: #b3e5c9;
  --tertiary-light-green: #39b54a;
  --quaternary-light-green: #fff9d2;
  --error-ligh-red: #f03127;
  --error-light-red: #fce0de;
  --error-red: #eb180d;
  --error-dark-red: #bf140b;
  --attention-yellow: #ffc000;
  --primary-yellow: #ff0;
  --attention-light-yellow: #fff4bf;
  --light-grey-blue-color: #eaeef3;
  --light-green-grey-color: #e1e5e3;
  --primary-light-grey: #f5f7f6;
  --after-primary-light-grey: #f7f7f7;
  --pre-secondary-light-grey: #f7f9fa;
  --secondary-light-grey: #f5f6f6;
  --tertiary-light-grey: #bebebe;
  --quaternary-light-grey: #e6e6e6;
  --quaternary-medium-grey: #cacfd3;
  --quintynary-light-grey: #9ca7b0;
  --primary-grey: #839c94;
  --secondary-grey: #878787;
  --tertiary-grey: #666;
  --quaternary-grey: #64727d;
  --primary-dark-grey: #494a49;
  --secondary-dark-grey: #313131;
  --tertiary-dark-grey: #2b292d;
  --quaternary-dark-grey: #444e55;
  --quintynary-dark-grey: rgb(100 114 125 / 18%);
  --shadow-dark-grey: #64727d2e;
  --freinacht-black: #1d2830;
  --primary-light-grey: #cacfd3;
  --whitesmoke-grey: #eff0f1;
}
